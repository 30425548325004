var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"h-full p-4"},[_c('VAppBar',{staticClass:"dark:bg-gray-700",attrs:{"dense":"","elevation":"1","color":"#F8FAFC"},scopedSlots:_vm._u([{key:"extension",fn:function(){return [_c('CategoryBreadcrumb',{attrs:{"category":_vm.currentCategory}})]},proxy:true}])},[_c('VToolbarTitle',[_vm._v(_vm._s(this.$t('menu.btnCategories')))]),_c('VSpacer'),_c('ReloadButton',{staticClass:"ml-2",on:{"click":_vm.reload}})],1),(_vm.loading)?_c('LoadingSpinner'):_vm._e(),_c('Grid',{staticClass:"pt-4",attrs:{"items":_vm.categories},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
return [_c('GridCategoryItem',{staticClass:"cursor-pointer",attrs:{"item":item}})]}}])}),_c('Grid',{class:{
      'pt-8': _vm.categories.length > 0,
    },attrs:{"items":_vm.categoryFiles},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var item = ref.item;
    var snackbarFn = ref.snackbarFn;
return [(_vm.id && !_vm.loading)?_c('GridFileItem',{staticClass:"cursor-pointer",attrs:{"item":item},on:{"snackbar":snackbarFn,"open-file":_vm.openFile}}):_vm._e()]}}])}),_c('FileViewer',{attrs:{"item":_vm.item},model:{value:(_vm.showFileDialog),callback:function ($$v) {_vm.showFileDialog=$$v},expression:"showFileDialog"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }