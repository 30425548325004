








import Vue, { PropType } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import { Category } from '@/interfaces/category.interface';
import { Breadcrumb } from '@/interfaces/breadcrumb.interface';
import { CategoryActions } from '@/store/category/actions.enum';

export default Vue.extend({
  name: 'CategoryBreadcrumb',

  props: {
    category: Object as PropType<Category>,
  },

  computed: {
    ...mapGetters('category', { categories: 'items' }),

    items(): Breadcrumb[] {
      let currentCategory = this.category;
      let items: Breadcrumb[] = [];

      if (!currentCategory) {
        items = [
          ...items,
          {
            text: 'Home',
            to: '/categories',
            disabled: false,
            exact: true,
          },
        ];

        this.setLevel(items.length - 1);

        return items;
      }

      while (currentCategory.parentIds.length > 0) {
        const parentId = currentCategory.parentIds[0];
        currentCategory = this.categories.find(
          (cat: Category) => cat.id === parentId,
        );

        items = [
          {
            text: currentCategory.title,
            to: `/categories/${currentCategory.id}`,
            disabled: false,
            exact: true,
          },
          ...items,
        ];
      }

      items = [
        ...items,
        {
          text: this.category.title,
          to: `/categories/${this.category.id}`,
          disabled: false,
          exact: true,
        },
      ];

      items = [
        {
          text: 'Home',
          to: '/categories',
          disabled: false,
          exact: true,
        },
        ...items,
      ];

      this.setLevel(items.length - 1);

      return items;
    },
  },

  methods: {
    ...mapActions('category', { setLevel: CategoryActions.SET_LEVEL }),
  },
});
