







































import Vue, { PropType } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import CategoryBreadcrumb from '@/components/CategoryBreadcrumb.vue';
import ReloadButton from '@/components/BaseReloadButton.vue';
import { Category } from '@/interfaces/category.interface';
import Grid from '@/components/Grid.vue';
import GridCategoryItem from '@/components/GridCategoryItem.vue';
import GridFileItem from '@/components/GridFileItem.vue';
import { CategoryActions } from '@/store/category/actions.enum';
import { LocaleActions } from '@/store/locale/actions.enum';
import OpenFileMixin from '@/mixins/open-file';
import IntervalReloadMixin from '@/mixins/interval-reload';
import LoadingSpinner from '@/components/LoadingSpinner.vue';

export default Vue.extend({
  name: 'Categories',

  mixins: [OpenFileMixin, IntervalReloadMixin],

  components: {
    ReloadButton,
    CategoryBreadcrumb,
    Grid,
    GridCategoryItem,
    GridFileItem,
    LoadingSpinner,
  },

  data() {
    return { unsubscribe: (null as unknown) as () => void };
  },

  props: { id: String as PropType<string> },

  watch: {
    // trigger reload when navigation changed
    $route: 'reload',
  },

  computed: {
    ...mapGetters('category', [
      'items',
      'rootCategories',
      'subCategories',
      'categoryFiles',
      'level',
      'loading',
    ]),

    currentCategory(): Category | null {
      if (!this.id) {
        return null;
      }

      return this.items.find((item: Category) => item.id === this.id);
    },

    categories(): Category[] {
      return this.id ? this.subCategories(this.id) : this.rootCategories;
    },
  },

  created() {
    this.unsubscribe = this.$store.subscribeAction(this.currentLocaleChanged);
  },

  destroyed() {
    this.unsubscribe();
  },

  methods: {
    ...mapActions('category', {
      fetch: CategoryActions.FETCH,
      fetchItems: CategoryActions.FETCH_ITEMS,
      clearCategoryFiles: CategoryActions.CLEAR_CATEGORY_FILES,
    }),

    async reload() {
      await this.clearCategoryFiles();

      if (!this.id) {
        this.fetch();
      } else {
        this.fetchItems(this.id);
      }
    },

    currentLocaleChanged(action: { type: string }) {
      if (
        // eslint-disable-next-line operator-linebreak
        action.type !== `locale/${LocaleActions.SET_CURRENT}` ||
        this.$route.name !== 'Category'
      ) {
        return;
      }

      if (this.level > 0) {
        this.$router.replace('/categories');
      }

      this.reload();
    },
  },
});
